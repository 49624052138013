////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useReducer, useState} from 'react';
import {ReportService} from "../services/ReportService";
import {reducer} from "../components/reducers/ReportsReducer";
import {ReportsState} from "../components/states/ReportsState";
import {ReportsActions} from "../Enums";
import {
  BlueButton,
  CenteringContainer,
  ContentScroller,
  ContentWrapper,
  EllipsisCell,
  FlexColumn,
  FlexColumnCentered,
  FlexFill,
  FlexRowCentered
} from "../CommonStyledComponents";
import {ReportUI} from "../dataModel/ReportUI";
import {ConvertRunDate} from "../converters/ConvertRunDate";
import {DownloadUrl, GetErrorMessage, uniqueDateDisplayFilter, uniqueFilter} from "../Utility";
import {Constants} from "../Constants";
import FilterItem from "../components/FilterItem";
import {ExportResultDto} from "../clients/V2Classes";
import {ReportTranslator} from "../dataModel/Translators/ReportTranslator";
import PartialLoadWarning from "../components/PartialLoadWarning";
import {CancellationToken} from "../dataModel/CancellationToken";
import {PageSizeService} from "../services/PageSizeService";
import Theme from "@adsk/alloy-react-theme";
import {ArrowRotateTwoIcon, CloudDownArrowIcon, TrashCanIcon} from "@adsk/alloy-react-icon";
import {BasicButton, IconButton, LinkButton} from "@adsk/alloy-react-button";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import SearchField from "@adsk/alloy-react-search-field";
import Illustration from "@adsk/alloy-react-illustration";
import Tooltip from "@adsk/alloy-react-tooltip";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import Panel from "@adsk/alloy-react-panel";
import FilterButton from '@adsk/alloy-react-filter-button';
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import Table from "../components/Table";

const service = new ReportService();

const pageSize = PageSizeService.GetPageSize('reports');
let paginationToken: string | undefined = undefined;
const cancelToken = new CancellationToken();

const Reports = () => {
  const [state, dispatch] = useReducer(reducer, new ReportsState());
  // Need to use 'useState' here because something is broken in the search field component with state binding
  const [search, setSearch] = useState('');
  const [loadingIds, setLoadingIds] = useState<{
    [key: string]: boolean
  }>({'temp': false});

  useEffect(() => {
    loadReports(true, false);
  }, []);

  function loadReports(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {
        loading: isFirstLoad || loadAll,
        loadingMoreData: !loadAll && !isFirstLoad,
        canCancelLoad: loadAll
      }
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    cancelToken.Cancel = false;

    const promise = loadAll
      ? service.GetRemainingReports(paginationToken, pageSize, cancelToken)
      : service.GetReports(paginationToken, pageSize);

    promise
      .then(paginationData => {
        paginationToken = paginationData.paginationData?.paginationToken;
        const reports = paginationData.items!.map(rpt => ReportTranslator.TranslateReport(rpt))

        if (!isFirstLoad) {
          state.reports.forEach(r => reports.push(r));
        }

        const defaultName = {value: '', label: Constants.NoFilterString};
        const nameOptions = [defaultName];
        const dynamicNames = reports.map(r => r.Name).filter(uniqueFilter).map(o => {
          return {value: o, label: o};
        });
        for (const name of dynamicNames) {
          nameOptions.push(name);
        }

        const defaultDate = {value: '', label: Constants.NoFilterString};
        const dateOptions = [defaultDate];
        const dynamicDates = reports
          .map(r => r.Date)
          .filter(uniqueDateDisplayFilter)
          .map(o => {
            return {value: ConvertRunDate.Convert(o), label: ConvertRunDate.Convert(o)};
          });
        for (const date of dynamicDates) {
          dateOptions.push(date);
        }

        dispatch({
          type: ReportsActions.multipleActions, payload: {
            reports: reports,
            filteredReports: reports,
            loading: false,
            loadingMoreData: false,
            hasMoreData: !paginationData.isDone,
            filterOptions: [
              {
                id: 'name',
                title: 'Name',
                selected: defaultName,
                options: nameOptions,
              },
              {
                id: 'date',
                title: 'Date',
                selected: defaultDate,
                options: dateOptions,
              }
            ]
          }
        })
      })
      .catch(error => {
        onError(error, 'Get reports');
        dispatch({
          type: ReportsActions.multipleActions,
          payload: {loading: false, loadingMoreData: false}
        });
      });
  }

  function processResult(result: ExportResultDto, reportName: string): void {
    const csv = result.exports == null ? null : result.exports!['csv'];

    if (csv == null) {
      alert('Error processing download result: export was null');
      return;
    }

    if (csv.failureMessage != null && csv.failureMessage.trim() !== '') {
      alert(`Error processing download result: ${csv.failureMessage}`);
      return;
    }

    DownloadUrl(csv.exportUri!, reportName);
  }

  function downloadReport(report: ReportUI): void {
    service.StartReportDownload(report)
      .then(result => {
        if (result.isReady) {
          modifyLoading([report.Id], false);
          processResult(result, report.Name);
          return;
        }

        const timeInterval = setInterval(() => {
          service.GetReportExportStatus(result.statusId!)
            .then(result => {
              if (result.isReady) {
                clearInterval(timeInterval);
                modifyLoading([report.Id], false);
                processResult(result, report.Name);
              }
            });
        }, 2000);

      })
      .catch(er => {
        modifyLoading([report.Id], false);
        onError(er, 'Download report');
      });
  }

  function deleteReports(reports: ReportUI[]): void {
    const ids = reports.map(r => r.Id);
    service.DeleteReports(ids)
      .then(result => {
        modifyLoading(ids, false);

        const newReports = [...state.reports];
        result.successfulIds?.forEach(id => {
          const found = newReports.find(r => r.Id === id);
          if (found != null) {
            newReports.splice(newReports.indexOf(found), 1);
          }
        });

        updateFilteredData(undefined, newReports);
        dispatch({
          type: ReportsActions.multipleActions,
          payload: {reports: newReports, checkedReports: [], globalCheck: false}
        });

        if (result.failedIds != null && result.failedIds.length > 0) {
          result.failedIds.forEach(f => {
            console.error(`Failed delete: ${f.id} - ${f.error}`);
          });

          alert(`Some reports failed to delete:\n\n${result.failedIds.map(f => `${f.id} - ${f.error}`).join('\n')}`);
        }
      })
      .catch(er => {
        modifyLoading(ids, false);
        alert(GetErrorMessage(er, 'Delete reports'));
      })
  }

  function processSelectedReports(f: 'download' | 'delete'): void {
    const reports: ReportUI[] = [];
    const newLoading = {...loadingIds};

    for (const id of state.checkedReports) {
      const report = state.reports.find(r => r.Id === id);
      if (report != null) {
        reports.push(report);
        newLoading[report.Id] = true;
      }
    }

    setLoadingIds(newLoading);

    if (f === 'delete') {
      deleteReports(reports);
    } else {
      for (const report of reports) {
        downloadReport(report);
      }
    }
  }

  function modifyLoading(ids: string[], isLoading: boolean): void {
    const newLoading = {...loadingIds};
    ids.forEach(id => newLoading[id] = isLoading);
    setLoadingIds(newLoading);
  }

  function searchChanged(e: any): void {
    setSearch(e);
    updateFilteredData(e);
  }

  function updateFilteredData(searchOverride?: string, reportOverride?: ReportUI[]): void {
    const actualSearch = searchOverride ?? search;
    const actualReports = reportOverride ?? state.reports;

    const filteredReports = actualReports.filter(r => {
      if (actualSearch != null && actualSearch !== '' && !r.Name?.toLowerCase().includes(actualSearch.toLowerCase())) {
        return false;
      }

      for (const filter of state.filterOptions) {
        if (filter.selected != null && filter.selected.value !== '') {
          switch (filter.id) {
            case 'name':
              if (r.Name !== filter.selected.value) {
                return false;
              }
              break;
            case 'date':
              if (ConvertRunDate.Convert(r.Date) !== filter.selected.value) {
                return false;
              }
              break;
          }
        }
      }

      return true;
    });

    const global = getGlobalCheckState(filteredReports, state.checkedReports);
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {filteredReports: filteredReports, globalCheck: global}
    });
  }

  function renderCheckHeader(): ReactElement {
    return (
      <Checkbox checked={state.globalCheck} onChange={c => globalCheckChange(c)}/>
    );
  }

  function renderCheckCell(report: ReportUI): ReactElement {
    return (
      <Checkbox
        checked={state.checkedReports.includes(report.Id)}
        onChange={c => itemCheckChange(report, c)}/>
    );
  }

  function itemCheckChange(report: ReportUI, checkState: CheckboxState): void {
    const existing = state.checkedReports.indexOf(report.Id);
    const newChecked = [...state.checkedReports];
    if (checkState === true && existing === -1) {
      newChecked.push(report.Id);
    } else if (checkState === false && existing > -1) {
      newChecked.splice(existing, 1);
    }

    const global = getGlobalCheckState(state.filteredReports, newChecked);
    dispatch({
      type: ReportsActions.multipleActions,
      payload: {checkedReports: newChecked, globalCheck: global}
    });
  }

  function globalCheckChange(checkState: CheckboxState): void {
    if (checkState === true) {
      const newCheck = [...state.checkedReports];
      for (const filteredReport of state.filteredReports) {
        if (!newCheck.includes(filteredReport.Id)) {
          newCheck.push(filteredReport.Id);
        }
      }
      dispatch({
        type: ReportsActions.multipleActions,
        payload: {checkedReports: newCheck, globalCheck: true}
      });
    } else if (checkState === false) {
      const newCheck = [...state.checkedReports];
      for (const filteredReport of state.filteredReports) {
        const index = newCheck.indexOf(filteredReport.Id);
        if (index > -1) {
          newCheck.splice(index, 1);
        }
      }
      dispatch({
        type: ReportsActions.multipleActions,
        payload: {checkedReports: newCheck, globalCheck: false}
      });
    } else {
      console.log(checkState);
    }
  }

  function getGlobalCheckState(reports: ReportUI[], checkedIds: string[]): CheckboxState {
    if (checkedIds.length === 0) {
      return false;
    }

    let matchingChecked = 0;
    for (const report of reports) {
      const inChecked = checkedIds.find(r => r === report.Id);
      if (inChecked != null) {
        matchingChecked++;
      }
    }

    return matchingChecked === 0 ? false : matchingChecked === reports.length ? true : 'indeterminate';
  }

  function renderSummaryCell(report: ReportUI): ReactElement {
    return (
      <EllipsisCell>
        {report.TotalFiles} total files - {report.SuccessFiles} succeeded
        / {report.FailedFiles} failed
      </EllipsisCell>
    )
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  return (
    <Panel.Container>
      <ContentScroller>
        <ContentWrapper>
          <FlexRowCentered>
            <h1 style={Theme.typography.heading1}>Reports</h1>
            {
              state.hasMoreData && state.reports.length > 0 &&
              <PartialLoadWarning pageSize={pageSize}
                                  onLoadAll={() => loadReports(false, true)}/>
            }
          </FlexRowCentered>
          <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
            <BlueButton onClick={() => processSelectedReports('download')}
                        style={{marginRight: '1em'}}
                        disabled={state.checkedReports.length === 0}>
              <CloudDownArrowIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Download</span>
            </BlueButton>
            <BasicButton onClick={() => processSelectedReports('delete')}
                         disabled={state.checkedReports.length === 0}>
              <TrashCanIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Delete</span>
            </BasicButton>
            <FlexFill/>
            <Tooltip content={'Refresh Reports'}>
              <IconButton
                onClick={() => loadReports(true, false)}
                renderIcon={() => <ArrowRotateTwoIcon/>}
                style={{marginRight: '1em'}}/>
            </Tooltip>
            <SearchField value={search}
                         placeholder={'Search reports...'}
                         onChange={searchChanged}
                         style={{width: '300px', marginRight: '1em', alignSelf: 'center'}}/>
            <FilterButton
              filtersOpen={state.filterOpen}
              toggleFiltersPanel={() => dispatch({type: ReportsActions.filterOpen, payload: !state.filterOpen})}
              clearFilters={() => null}/>
          </FlexRowCentered>
          {
            !state.loading &&
            <FlexColumn style={{flex: 1}}>
              <Table<ReportUI>
                style={state.reports.length === 0 ? {height: 'inherit'} : {}}
                columns={[
                  {
                    id: 'Select',
                    header: () => renderCheckHeader(),
                    cell: d => renderCheckCell(d.row.original),
                    size: 20,
                  },
                  {
                    id: 'Name',
                    accessorFn: r => r.Name,
                    header: () => 'Name',
                    cell: d => <EllipsisCell>{d.row.original.Name}</EllipsisCell>
                  },
                  {
                    id: 'Date',
                    accessorFn: r => r.Date,
                    header: () => 'Date',
                    sortingFn: 'datetime',
                    cell: d => <EllipsisCell>{ConvertRunDate.Convert(d.row.original.Date)}</EllipsisCell>,
                    size: 50
                  },
                  {
                    id: 'Summary',
                    accessorFn: r => `${r.TotalFiles} ${r.SuccessFiles} ${r.FailedFiles}`,
                    header: () => 'Summary',
                    cell: d => renderSummaryCell(d.row.original)
                  },
                  {
                    id: 'actions',
                    size: 20,
                    cell: d =>
                      loadingIds == null || !loadingIds[d.row.original.Id] ? null :
                        <ProgressRing size={'small'} style={{marginLeft: '0.4em'}}/>
                  },
                ]}
                data={state.filteredReports}
                renderLastRow={() => state.hasMoreData &&
                  <LoadMoreDataRow
                    isLoading={state.loadingMoreData}
                    onLoad={async () => loadReports(false, false)}
                    renderLoadMore={() =>
                      <FlexRowCentered>
                        <LinkButton onClick={() => loadReports(false, false)}>
                          <span style={Theme.typography.bodySmall}>Load more</span>
                        </LinkButton>
                        <LinkButton onClick={() => loadReports(false, true)} style={{marginLeft: '1em'}}>
                          <span style={Theme.typography.bodySmall}>Load all</span>
                        </LinkButton>
                      </FlexRowCentered>
                    }/>
                }/>
            </FlexColumn>
          }
          {
            state.loading &&
            (<CenteringContainer>
              <FlexColumnCentered>
                <ProgressRing size={'large'}/>
                {
                  state.canCancelLoad &&
                  <LinkButton onClick={() => cancelToken.Cancel = true}>Cancel</LinkButton>
                }
              </FlexColumnCentered>
            </CenteringContainer>)
          }
          {
            !state.loading && state.reports.length === 0 &&
            <CenteringContainer style={{flexDirection: 'column'}}>
              <Illustration type={'pagesTextGrey'} height={200} width={200}/>
              <p style={Theme.typography.bodyLarge}>You don't have any reports yet</p>
            </CenteringContainer>
          }
        </ContentWrapper>
      </ContentScroller>
      <Panel
        title={'Filter Reports'}
        open={state.filterOpen}
        onClose={() => dispatch({type: ReportsActions.filterOpen, payload: false})}>
        <Panel.Body>
          {state.filterOptions.map(o => {
            return (
              <FilterItem item={o} selected={o.selected} key={o.title} onSelectionChange={() => updateFilteredData()}/>
            )
          })}
        </Panel.Body>
      </Panel>
    </Panel.Container>
  );
};

export default Reports;