////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useReducer } from 'react';
import styled from "styled-components";
import { FlexRowCentered, FlexFill } from "../CommonStyledComponents";
import { useLocation } from "react-router";
import Tutorials from "./Tutorials";
import { reducer } from "./reducers/InfoMenuReducer";
import { InfoMenuState } from "./states/InfoMenuState";
import { InfoMenuActions } from "../Enums";
import { DirectDownloadTutorialPages, ReportsTutorialPages, TaskTutorialPages } from "../TutorialConstants";
import { PATHS } from "../Constants";
import { TutorialService } from "../services/TutorialService";
import { GetTutorialType } from "../Utility";
import {OpenIcon, QuestionCircleFilledIcon} from "@adsk/alloy-react-icon";
import {Flyout} from "@adsk/alloy-react-tooltip";
import Divider from "@adsk/alloy-react-divider";
import {MenuItem, MenuList} from "@adsk/alloy-react-menu";

const ItemWrapper = styled(FlexRowCentered)`
  width: 100%;
  padding: 10px 16px;
  text-align: left;
`;

const StyledOpenIcon = styled(OpenIcon)`
  margin-left: 16px;
`;

const tutorialService = new TutorialService();

const InfoMenu = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, new InfoMenuState());

  useEffect(() => {
    const tutorialType = GetTutorialType(location.pathname);
    if (tutorialType == null) {
      return;
    }
    if (tutorialService.ShouldTutorialDisplay(tutorialType)) {
      showTutorial();
    }
  }, [location]);

  function showTutorial() {
    let content: any = null;
    const tutorialType = GetTutorialType(location.pathname);
    switch (tutorialType) {
      case 'tasks':
        content = TaskTutorialPages;
        break;
      case 'reports':
        content = ReportsTutorialPages;
        break;
      case 'directDownload':
        content = DirectDownloadTutorialPages;
        break;
      default:
        return;
    }

    dispatch({
      type: InfoMenuActions.multipleActions,
      payload: {
        tutorialsOpen: true,
        tutorialContent: content
      }
    });
  }

  function tutorialClosed(dontShowChecked: boolean) {
    const tutorialType = GetTutorialType(location.pathname);
    if (tutorialType == null) {
      return;
    }
    tutorialService.SetTutorialDisplay(tutorialType, !dontShowChecked);
    dispatch({ type: InfoMenuActions.modalOpen, payload: false })
  }

  return (
    <Flyout
      trigger='click'
      offset={[0, 20]}
      style={{ padding: 0 }}
      placement={Flyout.PLACEMENTS.BOTTOM}
      content={
        <MenuList style={{ width: 300 }}>
          <MenuItem
            showSelection={false}
            onClick={showTutorial}
            disabled={
              !location.pathname.endsWith(PATHS.TASK)
              && !location.pathname.endsWith(PATHS.REPORT)
              && !location.pathname.endsWith(PATHS.DIRECT)
            }
            style={{
              justifyContent: 'space-between',
              padding: '0',
            }}
          >
            <ItemWrapper>
              <FlexFill>Tutorial</FlexFill>
            </ItemWrapper>
          </MenuItem>
          <MenuItem
            as="a"
            href={'https://help.autodesk.com/view/RTDOCS/ENU/'}
            target="_blank"
            rel="noreferrer noopener"
            showSelection={false}
            style={{
              justifyContent: 'space-between',
              padding: '0',
            }}
          >
            <ItemWrapper>
              <FlexFill>Help</FlexFill>
              <StyledOpenIcon size={16} />
            </ItemWrapper>
          </MenuItem>
          <MenuItem
            as="a"
            href={'https://www.youtube.com/playlist?list=PL0RZIBv0pCfvwTLguyhu6-D47PaCUIIdw'}
            target="_blank"
            rel="noreferrer noopener"
            showSelection={false}
            style={{
              justifyContent: 'space-between',
              padding: '0',
            }}
          >
            <ItemWrapper>
              <FlexFill>Learning on YouTube</FlexFill>
              <StyledOpenIcon size={16} />
            </ItemWrapper>
          </MenuItem>
          <Divider />
          <MenuItem
            as="a"
            href={'https://www.autodesk.com/company/terms-of-use/en/general-terms'}
            target="_blank"
            rel="noreferrer noopener"
            showSelection={false}
            style={{
              justifyContent: 'space-between',
              padding: '0',
            }}
          >
            <ItemWrapper>
              <FlexFill>Legal</FlexFill>
              <StyledOpenIcon size={16} />
            </ItemWrapper>
          </MenuItem>
          <MenuItem
            as="a"
            href={'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement'}
            target="_blank"
            rel="noreferrer noopener"
            showSelection={false}
            style={{
              justifyContent: 'space-between',
              padding: '0',
            }}
          >
            <ItemWrapper>
              <FlexFill>Privacy</FlexFill>
              <StyledOpenIcon size={16} />
            </ItemWrapper>
          </MenuItem>
        </MenuList>
      }>
      <QuestionCircleFilledIcon size={16} style={{ cursor: 'pointer' }} />
      <Tutorials open={state.tutorialsOpen}
                 content={state.tutorialContent}
                 onClose={tutorialClosed} />
    </Flyout>
  );
};

export default InfoMenu;